<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Déterminez la polarité relative des systèmes de solvant suivants pour la chromatographie (du
        moins au plus polaire)
      </p>

      <drag-to-rank-input-fr
        v-model="inputs.relativePolarities"
        :items="items"
        class="mb-0"
        style="max-width: 430px"
        prepend-text="Le plus polaire"
        append-text="Le moins polaire"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import DragToRankInputFr from '@/tasks/components/inputs/DragToRankInputFr.vue';

export default {
  name: 'uOttawa1321Prelab1Q5Fr',
  components: {
    DragToRankInputFr,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        relativePolarities: [],
      },
      items: [
        'Méthanol',
        '25% (v/v) acétate d’éthyle dans l’hexanes',
        'Dichlorométhane',
        '25% (v/v) éther dans le benzène',
      ],
    };
  },
};
</script>
